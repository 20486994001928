import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFaresPrimary from "../components/metro-fares-primary"

class P53ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="P53"
          description="View route information and buy your ticket for the P53 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">P53 service</h1>
              <p>Watergate - Methley Primary School</p>
              <p>Methley Primary School - Watergate</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="P53 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1fbc-kJpthbCT5MU12opRDqpacerBpi8&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Methley Primary School
                </p>
                <p>
                  From Watergate, Church Side, Church Lane, Mickletown Road, Main Street, Pinfold Lane, Saville Road.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="P53 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1I0VJXSNyU27PNq8cX7EBOlkhcw_8N4g&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Methley Primary School
                </p>
                <p>
                  From Savile Road, Church Lane, Church Side, Watergate.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mexborough Arms</td>
                      <td>DEP</td>
                      <td>0825</td>
                    </tr>
                    <tr>
                      <td>Watergate/Embleton Rd</td>
                      <td>DEP</td>
                      <td>0830</td>
                    </tr>
                    <tr>
                      <td>Watergate/Pinders Green</td>
                      <td>DEP</td>
                      <td>0832</td>
                    </tr>
                    <tr>
                      <td>Church Lane</td>
                      <td>DEP</td>
                      <td>0837</td>
                    </tr>
                    <tr>
                      <td>Methley Primary School</td>
                      <td>ARR</td>
                      <td>0840</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Methley Primary School</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Embleton Estate</td>
                      <td>DEP</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Watergate</td>
                      <td>ARR</td>
                      <td>1550</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFaresPrimary />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default P53ServicePage
